import React from 'react'

const Header = () => (
  <header>
    <div className="header__logo"></div>
    this is the header
  </header>
)

export default Header
