import React, { Fragment } from 'react'

import Header from './Header'

const Layout = () => (
  <Fragment>
    <Header />
    <main>This is the main</main>
  </Fragment>
)

export default Layout
